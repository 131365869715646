import styled from "styled-components"

const Button = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 1000px; /* perfectly rounded corners */
  padding: ${props => props.theme.verticalSpacing(0.5)}
    ${props => props.theme.horizontalSpacing(3)}; /* the second value here doesn't match other spacing */

  background-color: ${props => props.theme.highlightBlue};
  color: white;
  font-size: 1.6rem;
  line-height: 3.2rem;
  font-weight: bold;
  position: relative;

  &[disabled] {
    background-color: ${props => props.theme.highlightBlue};
    opacity: 0.3;
    cursor: initial;
  }

  ${'' /* :not([disabled]):hover {
    box-shadow: 0 0 0 3px ${props => props.theme.oilslick};
  } */}

  ::after {
    content: '';
    transition: opacity 300ms;
    opacity: 0;
  }

  ${'' /* if opacity is needed */}
  :not([disabled]):hover::after {
    content: '';
    position: absolute;
    top: -3px;
    bottom: -3px;
    right: -3px;
    left: -3px;
    border: 3px solid ${props => props.theme.oilslick};
    border-radius: 1000px;
    opacity: .16;
  }
`

export default Button
