import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/archive/layout"
import SEO from "../components/archive/seo"
import Grid from "../components/archive/lib/grid"
import Jumbotron from "../components/archive/jumbotron"
import styled from "styled-components"
import Button from "../components/archive/lib/button"

export default function CareerTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout initialHeaderLight={true}>
      <SEO title={`Careers - ${frontmatter.title}`} />
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <Breadcrumb to="/careers">
              <LeftChevron />
              <span>Careers</span>
            </Breadcrumb>
          </Grid.Col>
          <PageHeader>
            <JobTitle>{frontmatter.title}</JobTitle>
            <Button
              as="a"
              href={
                frontmatter.link ??
                `mailto:contact@pumpjackdataworks.com?subject=${frontmatter.title} Position`
              }
              css={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply
            </Button>
          </PageHeader>
        </Grid.Row>
      </Grid>
      <GreyBackground>
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <PageBody dangerouslySetInnerHTML={{ __html: html }} />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </GreyBackground>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        link
      }
    }
  }
`

const PageHeader = styled(Grid.Col)`
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const JobTitle = styled(Jumbotron.Title)`
  font-size: 2.4rem;
  margin: 0;

  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    font-size: 4.8rem;
  }
`
const GreyBackground = styled.div`
  margin: 0;
  background: ${props => props.theme.oilslick10};
  margin-bottom: 0;
  padding-bottom: 6rem;
  padding-top: 2rem;
`

const PageBody = styled.div`
  line-height: 1.6;

  h2 {
    font-weight: 400;
    font-size: 2.4em;
  }

  h3 {
    font-weight: 400;
    font-size: 1.8em;
  }

  p,
  li {
    font-size: 1.4em;
    opacity: 0.75;
  }

  ul {
    list-style-type: none;
    list-style-position: outside;
    padding: 0;

    li {
      &::before {
        margin-left: -0.7em;
        content: "- ";
      }
    }
  }
`

const Breadcrumb = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 1.25rem;
  color: inherit;
  text-decoration: none;
  font-size: 1.8rem;
  opacity: 0.6;

  @media (min-width: ${props => props.theme.tablet}px) {
    font-size: 2.4rem;
  }

  span {
    display: block;
    margin-left: 0.25em;
  }
`

const ColorSvg = styled.svg`
  fill: currentColor;
`
// TODO: use @tixsee/uniform
function LeftChevron() {
  return (
    <ColorSvg viewBox="3 0.5 9 15" width="0.6em">
      <path d="M11.652 1.116a1.25 1.25 0 01.09 1.666l-.09.102L6.535 8l5.117 5.116a1.25 1.25 0 01.09 1.666l-.09.102a1.25 1.25 0 01-1.667.091l-.101-.091L3 8l6.884-6.884a1.25 1.25 0 011.768 0z" />
    </ColorSvg>
  )
}
